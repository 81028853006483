.city-marker {
  position: relative;
  height: 100%;
  width: 100%;
  transform: rotate(-45deg) translate(50%, -50%);

  border: 4px solid $color-white;
  border-radius: 50% 50% 50% 0;
  transition: background-color .1s;

  &--past {
    background-color: $color-light;
  }

  &--current {
    background-color: $color-accent-orange;
  }

  &--future {
    background-color: $color-accent-violet;
  }

  &:hover {
    background-color: $color-main;
  }
}

.popup-list {
  margin: 0 0 $gap-regular 0;
  padding-left: 20px;

  &--past {
    color: $color-light;
  }

  &--current {
    color: $color-accent-orange;
  }

  &--future {
    color: $color-accent-violet;
  }
}

.popup-title {
  margin: 0;
  font-size: $font-size-regular;

  &--past {
    color: $color-light;
  }

  &--current {
    color: $color-accent-orange;
  }

  &--future {
    color: $color-accent-violet;
  }
}

.country-polygon {
  cursor: inherit;
  fill-opacity: 0.8;
  stroke-width: 1;
}
