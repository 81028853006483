.text {
  background-color: $color-white;
  padding: $gap-xl;
  border: $gap-regular solid $color-light;
  border-top-left-radius: $border-radius;

  p {
    line-height: 1.6;
  }

  h2,
  p {
    margin-bottom: $gap-large;
  }

  a {
    color: $color-light;
    transition: color .1s;

    &:hover {
      color: $color-main;
    }
  }

  ul {
    margin: 0;
    list-style-type: disclosure-closed;
  }
}
