* {
  box-sizing: border-box;
}

button {
  border: none;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}

input {
  border: none;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: inherit;
}

h1,
h2,
p {
  margin: 0;
}
