.nav-button {
  position: fixed;
  top: $vertical-offset;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: $width-navigation;
  max-width: 90px;
  height: $height-navigation;

  text-align: center;
  text-decoration: none;

  transition: background-color .1s;

  &:hover {
    background-color: $color-light;

    .nav-button__icon {
      color: $color-main;
    }
  }
}

.nav-button__icon {
  color: $color-white;
  font-size: $font-size-nav-icon;
  transition: color .1s;
}

.nav-button__text {
  color: $color-main;
  font-size: $font-size-small;
  margin-top: $gap-large;
  white-space: pre-line;
}

.nav-button--next {
  left: $horizontal-offset-nav-button;
  border-top-right-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
}

.nav-button--back {
  right: $horizontal-offset-nav-button;
  border-top-left-radius: $border-radius;
  border-bottom-left-radius: $border-radius;
}
