@media (max-width: 1200px) {
  .overview {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 939px) {
  .header__titles .title {
    font-size: $font-size-header-small;
  }
}

@media (max-width: 900px) {
  .overview {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .header__titles .title {
    font-size: $font-size-large;
  }

  .nav-button__text {
    display: none;
  }
}

@media (max-width: 692px) {
  .alert {
    width: 60vw;
  }

  .content-wrapper {
    margin-top: $vertical-offset-with-double-header;

    &--scroll {
      height: $height-content-scrollable-low;
    }
  }

  .header {
    flex-wrap: wrap;
    height: $height-header-double;

    &__titles {
      width: 100%;
      height: 50%;
      border-top: $gap-small solid;

      .title {
        font-size: $font-size-header-small;
      }

      &--borders {
        border-left: none;
        border-right: none;
      }
    }

    &__icon {
      height: 50%;
      order: 1 !important;
    }
  }

  .info {
    flex-wrap: wrap;

    &__sidebar {
      padding-bottom: $height-header;
    }

    &__text {
      margin-right: 0;
      margin-bottom: $gap-regular;
    }
  }

  .nav-button {
    top: $vertical-offset-with-double-header;
  }
}

@media (max-width: 635px) {
  .photo-grid {
    grid-template-columns: 1fr;
    grid-column-gap: 0;

    &__photo {
      grid-column-end: 2 !important;
    }
  }
}

@media (max-width: 592px) {
  .calendar {
    height: auto;
    grid-template-columns: repeat(2, 1fr);

    &--events {
      display: none;
    }

    &__day {
      height: 120px;

      &:nth-of-type(odd) {
        grid-column-end: -2;
        grid-column-start: 1 !important;
      }

      &:nth-of-type(even) {
        grid-column-end: -1;
        grid-column-start: 2 !important;
      }
    }

    &__thumbnail {
      height: 100%;
    }
  }

  @media (max-width: 900px) {
    .overview {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

@media (max-width: 525px) {
  .comment {
    &__form {
      flex-direction: column;

      .comment__info {
        order: 2;
      }
    }

    &__info {
      min-width: 110px;
    }
  }

  .comments {
    padding: $gap-regular 0 0;
  }

  .content-wrapper {
    margin-top: $vertical-offset-triple;
    width: 100vw;

    &--scroll {
      height: $height-content-low;
    }
  }

  .header {
    width: 100vw;
  }

  .nav-button {
    position: absolute;
    height: $height-header;
    top: $vertical-offset-with-double-header;
    font-size: $font-size-nav-icon-small;
    width: $width-half-with-gap;
    max-width: none;

    background-color: $color-light;

    &--back {
      left: $gap-regular;
      margin-right: $gap-small;
    }

    &--next {
      left: auto;
      right: $gap-regular;
      margin-left: $gap-small;
    }

    &__icon {
      color: $color-main;
    }
  }

  .slideshow {
    width: 100vw;
    height: $height-content-low;
    left: 0;
    top: $vertical-offset-triple;

    &__close {
      transform: none;
      right: $gap-regular;
    }
  }
}

@media (max-width: 444px) {
  .alert {
    width: 90vw;
  }

  .info {
    width: 100vw;

    &__sidebar {
      padding-bottom: 0;

    }
  }
}
