// LEAFLET MAP
.leaflet-container {
  width: 100%;
  height: 100%;

  .leaflet-control-attribution {
    background-color: transparent;
  }
}

.leaflet-touch .leaflet-bar {
  border: none;

  a {
    background-color: $color-white;
    color: $color-light;
    border-bottom: 1px solid $color-main;
    transition: color .1s, background-color .1s;

    &:first-child {
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
    }

    &:last-child {
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
      border-bottom: none;
    }

    &:hover {
      background-color: $color-light;
      color: $color-white;
    }

    &.leaflet-disabled {
      color: $color-gray;
      background-color: $color-white;
    }
  }
}

.leaflet-control-attribution {
  color: $color-light;
  background-color: transparent;

  a {
    color: $color-light;
  }
}

.leaflet-popup-content-wrapper {
  background-color: $color-white;
  color: $color-light;
  font-family: 'Raleway', sans-serif;
  font-weight: 300;
  font-size: $font-size-regular;
}

.leaflet-popup-content h2 {
  color: $color-main;
  font-size: $font-size-large;
  border-bottom: 1px solid $color-main;
  margin-bottom: $gap-regular;
}

.leaflet-popup-content a {
  display: block;
  margin-top: $gap-small;
  color: $color-main;
  transition: color .1s;

  &:hover {
    color: $color-light;
  }
}

.leaflet-popup-tip {
  background-color: $color-white;
}

.leaflet-container a.leaflet-popup-close-button {
  height: auto;
  width: auto;
  margin: $gap-regular $gap-regular 0 0;
  padding: 0;
  color: $color-main;
  font-size: $font-size-large;
  transition: color .1s;

  &:hover {
    color: $color-light;
  }
}

// QUILL EDITOR
.ql-editor {
  min-height: $height-editor;
  font-size: $font-size-regular;
}

.ql-container {
  // remove toolbar height from container height
  height: calc(100% - 42px);
  font-family: inherit;
}

.ql-snow .ql-stroke {
  stroke: $color-main;
}
