.calendar {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: $gap-regular;
  width: 100%;
  height: 100%;
}

// DAYS
.calendar__day {
  position: relative;
  background-color: $color-white;
  overflow: hidden;
  cursor: default;

  &:hover {
    .calendar__thumbnail {
      box-shadow: inset $box-shadow-hover;
    }

    .calendar__number {
      box-shadow: $box-shadow-hover;
    }
  }

  &--today {
    .calendar__number {
      background-color: $color-light;
    }
  }
}

.calendar__thumbnail {
  position: absolute;
  top: 0;
  left: 0;

  display: block;
  height: $height-calendar-thumbnail;
  width: 100%;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  opacity: .8;

  transition: box-shadow .1s;
}

.calendar__number {
  position: absolute;
  top: 0;
  left: 0;

  width: 25%;
  height: 25%;
  margin: 0;
  padding-right: $gap-regular;

  border-bottom-right-radius: $border-radius;
  background-color: $color-main;
  color: $color-white;
  text-align: center;

  transition: box-shadow .1s;
}

.calendar__icon {
  position: absolute;
  top: $gap-small;
  right: $gap-small;

  pointer-events: none;
}

// EVENTS
.calendar--events {
  position: absolute;
  top: 0;
  left: 0;
  align-items: end;
  pointer-events: none;
}

.calendar__event {
  height: $height-calendar-event;
  padding: 0 $gap-regular 0 $gap-small;
  overflow: hidden;
  color: inherit;

  font-size: $font-size-small;
  line-height: 2.1;
  font-weight: $font-weight-regular;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-decoration: none;

  pointer-events: all;
  cursor: pointer;

  transition: color .1s, background-color .1s;

  &--0,
  &--6,
  &--8 {
    background-color: $color-accent-orange;
  }

  &--1,
  &--3,
  &--7,
  &--9 {
    color: $color-white;
    background-color: $color-accent-violet;
  }

  &--2,
  &--5,
  &--10 {
    background-color: $color-accent-yellow;
  }

  &--4,
  &--11 {
    color: $color-white;
    background-color: $color-accent-red;
  }
}

.calendar__event--dummy {
  pointer-events: none;
}

.calendar__event--focussed {
  background-color: $color-light;
  color: $color-white;
}
