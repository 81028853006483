.photo-grid {
  display: grid;
  grid-gap: $gap-regular;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-auto-rows: 13vh;
  grid-auto-flow: dense;
}

.photo-grid__photo {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  cursor: pointer;

  &:hover {
    box-shadow: inset $box-shadow-hover;
  }
}

.photo-grid__play-icon {
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  font-size: 60px;
  color: $color-main;
}

.photo-grid__description {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 8px;

  background-color: $color-white;
  color: $color-main;
  line-height: 1.4;
  text-decoration: none;
}

.photo-grid__text {
  background-color: $color-white;
  padding: $gap-xl;
  border: $gap-regular solid $color-light;
  border-top-left-radius: $border-radius;

  p {
    line-height: 1.6;
  }

  h2,
  p:not(:last-of-type) {
    margin-bottom: $gap-large;
  }

  a {
    color: $color-light;
    transition: color .1s;

    &:hover {
      color: $color-main;
    }
  }

  ul {
    margin: 0 0 $gap-large;
    list-style-type: disclosure-closed;
  }

  li {
    margin-bottom: $gap-small;
  }
}
