.admin__section {
  width: 100%;

  border: 2px solid;
  color: $color-white;

  &:not(:last-child) {
    margin-bottom: $gap-regular;
  }
}

.admin__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  width: 100%;
  min-height: $height-header;

  &.admin__row--wrapped {
    flex-wrap: wrap;
  }
  &.admin__row--small {
    min-height: 0;
  }

  .input {
    margin-left: $gap-regular;
  }
}

.admin__select {
  height: $height-button;
  font-family: inherit;
  font-size: inherit;
  min-width: 200px;
  padding-right: 20px;
}

.admin__select option[disabled] {
  background-color: $color-light;
}

.tab-bar {
  display: flex;
  justify-content: stretch;

  width: 100%;
}

.tab-bar__item {
  flex-grow: 1;

  padding: 0;

  border: 1px solid;
}
.tab-bar__label {
  display: block;
  padding: $gap-regular;

  width: 100%;
  height: 100%;
}

.tab-bar__input {
  display: none;

  &:checked + .tab-bar__label {
    background: $color-light;
  }
}

.admin__text-field {
  flex-basis: 100%;
  margin-top: $gap-regular;

  background-color: $color-white;
  color: $color-main;
}

.admin__messages {
  flex-basis: 33%;

  h2 {
    font-size: $font-size-large;
  }

  p {
    white-space: pre-line;
  }
}
