.comments {
  padding: $gap-regular 0 $vertical-offset;
}

.comments__header {
  height: $height-button;
  margin-bottom: $gap-regular;
  padding-left: $gap-regular;
  line-height: $height-button;
  font-size: $font-size-large;
  background-color: $color-light;
}

.comment {
  max-width: 800px;
  margin: 0 auto $gap-regular;
  display: flex;
  flex-direction: row;

  .button {
    margin-top: $gap-regular;
  }

  .input {
    margin: $gap-small 0 $gap-large;
  }
}

.comment__info {
  flex-basis: 30%;
  min-width: 210px;

  display: flex;
  flex-direction: column;
  padding: $gap-large;
  background-color: $color-light;
}

.comment__author {
  font-weight: 400;
}

.comment__date {
  font-size: $font-size-small;
}

.comment__hint {
  font-size: $font-size-small;
  line-height: 1.6;
  margin: -$gap-large 0 $gap-large;
}

.comment__text {
  flex-basis: 70%;
  padding: $gap-large;
  background-color: $color-white;
  word-wrap: anywhere;

  a {
    color: $color-light;
    transition: color .1s;

    &:hover {
      color: $color-main;
    }
  }

  ul {
    margin: 0;
    list-style-type: disclosure-closed;
  }
}

.comment__editor {
  height: 100%;
}
