.slideshow {
  position: absolute;
  top: 0;
  left: 10vw;

  width: $width-content;
  height: 100vh;

  background-color: $color-overlay;
  z-index: $z-index-slideshow;
}

.slideshow__media {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  border-top: $gap-regular solid transparent;
  border-bottom: $gap-regular solid transparent;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.slideshow__video {
  width: 100%;
}

.slideshow__close {
  position: absolute;
  top: $gap-regular;
  right: -$gap-regular;
  transform: translateX(100%);
  
  color: $color-white;
  font-size: $font-size-nav-icon-small;
  transition: color .1s;

  &:hover {
    color: $color-light;
  }
}
