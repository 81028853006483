html, body {
  margin: 0;
  background-color: $color-main;
  color: $color-main;

  font-family: 'Raleway', Verdana, sans-serif;
  font-weight: $font-weight-light;
  font-size: $font-size-regular;
}

.content-wrapper {
  position: relative;
  width: $width-content;
  height: $height-content;
  margin: $vertical-offset auto 0;
}

.content-wrapper--narrow {
  max-width: 900px;
}

.content-wrapper--scroll {
  height: $height-content-scrollable;
}

.button {
  height: $height-button;
  min-width: $min-width-button;

  border-radius: $border-radius;
  background-color: $color-light;
  color: $color-white;

  font-family: inherit;
  font-size: inherit;

  &:hover:not(:disabled) {
    background-color: $color-white;
    color: $color-light;
  }

  &:disabled {
    background-color: $color-gray;
    cursor: not-allowed;
  }

  &--dark {
    background-color: $color-main;
  }
}

.input {
  height: $height-input;
  padding-left: $gap-small;
  background-color: $color-white;
  color: $color-main;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: $color-overlay;
  z-index: $z-index-overlay;
}

.loader {
  height: 200px;
  width: 200px;
  fill: $color-white;
  animation: rotation 1s infinite linear;
}

.loader__message {
  margin-top: $gap-large;
  color: $color-white;
  line-height: 1.4;
  white-space: pre-line;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
