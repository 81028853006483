.header {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: $z-index-header;

  display: flex;
  justify-content: space-between;
  align-items: center;

  height: $height-header;
  width: $width-content;
  padding: 0 30px;

  background-color: $color-white;
  color: $color-main;

  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
}

.header__titles {
  flex-grow: 1;
  order: 3;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;

  .title {
    font-size: $font-size-header;
  }
}

.header__titles--borders {
  border-left: $gap-small solid $color-main;
  border-right: $gap-small solid $color-main;
}

.header__icon {
  position: relative;
  height: 100%;
  width: $height-header;

  color: $color-main;
  font-size: 40px;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;

  &:hover {
    color: $color-light;
  }
}

.header__comment-number {
  position: absolute;
  top: 20%;
  left: 43%;

  font-size: $font-size-large;
  font-weight: $font-weight-regular;
}
