.info {
  display: flex;
  flex-wrap: nowrap;

  .button {
    margin-top: $gap-regular;
  }

  .input {
    margin: $gap-small 0 $gap-large;
  }
}

.info__text {
  flex-basis: 70%;
  flex-grow: 1;
  margin-right: $gap-regular;
  overflow-y: scroll;
  white-space: pre-line;
}

.info__sidebar {
  flex-basis: 30%;
  flex-grow: 1;
}

.info__image {
  height: 60%;
  min-height: 300px;
  width: 100%;
  margin-bottom: $gap-regular;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.info__form {
  height: calc(40% - #{$gap-regular});
  width: 100%;
  padding: $gap-large;

  display: flex;
  flex-direction: column;

  background-color: $color-light;
}

.info__form-title {
  font-size: $font-size-regular;
}
