.overview {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: $gap-small;
}

.ov-month {
  height: $height-overview-month;
  padding: $gap-small;

  &:hover {
    padding: 3px;
    border: 1px solid $color-white;
  }
}

.ov-month__header {
  margin-bottom: $gap-small;
}

.ov-month__image {
  display: block;
  height: .5 * $height-overview-month;
  width: 100%;

  background-size: cover;
  background-position: center;
}

.ov-month__link {
  display: block;
  width: 100%;
  height: 100%;
  padding: 2px $gap-small;

  color: $color-white;
  text-decoration: none;

  &:hover {
    background-color: $color-light;
    color: $color-white;
  }
}

.ov-grid-wrapper {
  position: relative;
}

.ov-grid {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: $gap-small;
}

.ov-grid__day {
  height: $height-calendar-event;
  background-color: rgba($color-white, .1);
}

.ov-grid__event {
  height: $height-calendar-event;
  padding: 0 $gap-regular 0 $gap-small;
  overflow: hidden;
  color: inherit;

  font-size: $font-size-small;
  line-height: 2.1;
  font-weight: $font-weight-regular;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-decoration: none;
  cursor: pointer;

  >.event-name:not(:empty) {
    padding-left: $height-calendar-event + $gap-small;
  }

  &:not(.dummy-event) {
    background-size: $height-calendar-event;
    background-position: $gap-small;
    background-repeat: no-repeat;
  }

  &--neutral {
    background-color: rgba($color-light, .85);
    color: $color-white;
  }

  &--0,
  &--6,
  &--8 {
    background-color: $color-accent-orange;
  }

  &--1,
  &--3,
  &--7,
  &--9 {
    color: $color-white;
    background-color: $color-accent-violet;
  }

  &--2,
  &--5,
  &--10 {
    background-color: $color-accent-yellow;
  }

  &--4,
  &--11 {
    color: $color-white;
    background-color: $color-accent-red;
  }
}
