.alert-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: $color-overlay;
}

.alert {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  width: 40vw;
  min-height: 30vh;
  padding: $gap-large;
  background-color: $color-white;
  border: $gap-regular solid $color-light;

  .button:hover {
    border: 2px solid $color-light;
  }
}
