// COLORS
$color-main: rgb(8, 47, 57);
$color-light: rgb(36, 152, 135);
$color-white: rgb(241, 240, 232);
$color-gray: #999; // use only for disabled state

$color-accent-red: rgb(206, 39, 58);
$color-accent-yellow: rgb(239, 202, 8);
$color-accent-orange: rgb(242, 129, 0);
$color-accent-violet: rgb(163, 63, 156);

$color-overlay: rgba(8, 47, 57, .9);

// EFFECTS
$box-shadow-hover: 0 0 1px 2px $color-white;
$border-radius: 60px;

// GAPS
$gap-xl: 28px;
$gap-large: 16px;
$gap-regular: 8px;
$gap-small: 4px;

// HEIGHT
$height-input: 32px;
$height-button: 40px;
$height-header: 60px;
$height-header-double: 2 * $height-header;
$height-calendar-event: 26px;
$height-calendar-thumbnail: calc(100% - #{$height-calendar-event});
$height-editor: 160px;
$height-overview-month: 420px;

// WIDTH
$min-width-button: 120px;
$width-content: 80vw;
$width-navigation: calc(10vw - (2 * #{$gap-regular}));

// FONT
$font-weight-regular: 400;
$font-weight-light: 300;

$font-size-nav-icon: 80px;
$font-size-nav-icon-small: 40px;
$font-size-header: 28px;
$font-size-header-small: 23px;
$font-size-large: 20px;
$font-size-regular: 16px;
$font-size-small: 13px;

// Z INDEX
$z-index-header: 2;
$z-index-slideshow: 3;
$z-index-overlay: 1001; // to display above Leaflet map elements

// DERIVED PROPERTIES
$vertical-offset: $height-header + $gap-regular;
$vertical-offset-double: (2 * $height-header) + (2 * $gap-regular);
$vertical-offset-with-double-header: $height-header-double + $gap-regular;
$vertical-offset-triple: (3 * $height-header) + (2 * $gap-regular);
$horizontal-offset-nav-button: calc(90vw + #{$gap-regular});

$height-content-scrollable: calc(100vh - #{$vertical-offset});
$height-content-scrollable-low: calc(100vh - #{$vertical-offset-with-double-header});
$height-content: calc(100vh - #{$vertical-offset-double});
$height-content-low: calc(100vh - #{$vertical-offset-triple});
$height-navigation: $height-content;

$width-half-with-gap: calc(50vw - (#{$gap-regular} + #{$gap-small}));
